/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "material-design-lite";

$padding: 24px;

body {
  margin: 0px;
}

.styleguide-demo h1 {
  margin: ($padding * 2) $padding 0 $padding;
}

.styleguide-demo h1:after {
  content: '';

  display: block;
  width: 100%;

  border-bottom: 1px solid rgba(0,0,0,0.5);
  margin-top: $padding;
}

.styleguide-demo {
  opacity: 0;

  transition: opacity 0.6s ease;
}

.styleguide-masthead {
  height: 256px;
  background: unquote("rgb(#{nth($palette-grey, 10)})");
  padding: 115px 16px 0;
}

.styleguide-container {
  position: relative;
  max-width: 960px;
  width: 100%;
}

.styleguide-title {
  color: #fff;
  bottom: auto;
  position: relative;
  font-size: 56px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.02em;

  &:after {
    border-bottom: 0px;
  }

  span {
    font-weight: 300;
  }
}

.mdl-styleguide .mdl-layout__drawer .mdl-navigation__link {
  padding: 10px 24px;
}

.demosLoaded .styleguide-demo {
  opacity: 1;
}

iframe {
  display: block;

  width: 100%;

  border: none;
}

iframe.heightSet {
  overflow: hidden;
}

.demo-wrapper {
  margin: $padding;

  iframe {
    border: 1px solid rgba(0,0,0,0.5);
  }
}
